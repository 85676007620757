const location: any = window.location;
// const { protocol } = location;
const protocol = "https:"  /* Uncomment for dev */

export const queriesAccountUserId = "5db0e68668c60000078f7d49";

const envUrls = {
  'staging': {
    'app': 'app-staging',
    'collaborators': 'collaborators-staging',
    'jobowners': 'jobowners-staging',
    'links': 'links-staging',
    'matches': 'matches-staging',
    'oauth-pub': 'oauth-pub-staging',
    'pub': 'pub-staging',
    'search': 'search-staging',
    'settings': 'settings-staging',
    'sourcing': 'sourcing-staging',
  },
  'testing': {
    'app': 'app-testing',
    'collaborators': 'collaborators-testing',
    'jobowners': 'jobowners-testing',
    'links': 'links-testing',
    'matches': 'matches-testing',
    'oauth-pub': 'oauth-pub-testing',
    'pub': 'pub-testing',
    'search': 'search-testing',
    'settings': 'settings-testing',
    'sourcing': 'sourcing-testing',
  },
  'prod': {
    'app': 'app',
    'collaborators': 'collaborators',
    'jobowners': 'jobowners',
    'links': 'links',
    'matches': 'matches',
    'oauth-pub': 'oauth-pub',
    'pub': 'pub',
    'search': 'search',
    'settings': 'settings',
    'sourcing': 'sourcing',
  }
}

const getUrl = (name: string) => {
  const env: string = process.env.REACT_APP_DEPLOY_ENV || process.env.DEPLOY_ENV || 'prod';
  const subdomain = env == 'preprod' ? envUrls['prod'][name] : envUrls[env][name];
  const domain =  env == 'preprod' ? 'findem-preprod.ai' : 'findem.ai';
  return `${subdomain}.${domain}`;
}

export const appUrl = `${protocol}//${getUrl('app')}`;
export const collabUrl = `${protocol}//${getUrl('collaborators')}`;
export const jobOwnersUrl = `${protocol}//${getUrl('jobowners')}`;
export const linksUrl = `${protocol}//${getUrl('links')}`;
export const matchesUrl = `${protocol}//${getUrl('matches')}`;
export const oauthUrl = `${protocol}//${getUrl('oauth-pub')}`;
export const pubUrl = `${protocol}//${getUrl('pub')}`;
export const searchUrl = `${protocol}//${getUrl('search')}`;
export const settingsUrl = `${protocol}//${getUrl('settings')}`;
export const sourcingUrl = `${protocol}//${getUrl('sourcing')}`;
