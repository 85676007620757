export class User {
  uid: string;
  prid: string;
  email: string;
  name: string;
  company: string;
  upgraded = false;
  role: string;
  roles: string[];
  token: string; // TODO should be removed
  phoneno: string;
  jobOwner: User;


  constructor(data: any) {
    this.setData(data);
  }

  setData(data: Partial<User>) {
    Object.assign(this, data);
  }

  update(data: Partial<User>): User {
    this.setData(data);
    return this;
  }

  clone(): User {
    return new User(this);
  }
}

export interface UserGlobal {
  roles?: string[];
}