import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/fonts/icons/style.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';

import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BaseProvider, LightTheme } from 'baseui';
const engine = new Styletron();

ReactDOM.render(
  // <React.StrictMode>
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme} zIndex={1500}>
      <App />
    </BaseProvider>
  </StyletronProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
