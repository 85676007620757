import { Campaign } from '../../models/Campaign';
import { Variable } from '../../models/Variable';
import ICP from '../../models/ICP';
import { Collaborator } from '../../models/Collaborator';
import { Candidate } from '../../models/Candidate';

export enum CampaignRevampActions {
  SET_EDIT_MODE = 'SET_EDIT_MODE',
  SET_PREVIEW_MODE = 'SET_PREVIEW_MODE',
  SET_SELECTED_CAMPAIGN = 'SET_SELECTED_CAMPAIGN',
  UPDATE_SELECTED_CAMPAIGN = 'UPDATE_SELECTED_CAMPAIGN',
  MARK_CAMPAIGN_FORM_VALID = 'MARK_CAMPAIGN_FORM_VALID',
  MARK_CAMPAIGN_FORM_INVALID = 'MARK_CAMPAIGN_FORM_INVALID',
  MARK_VARIABLES_VALID = 'MARK_VARIABLES_VALID',
  MARK_VARIABLES_INVALID = 'MARK_VARIABLES_INVALID',
  UPDATE_NON_EXISTING_VARIABLES = 'UPDATE_NON_EXISTING_VARIABLES',
  UPDATE_ALLOW_NON_EXISTING_VARIABLES = 'UPDATE_ALLOW_NON_EXISTING_VARIABLES',
  SET_VARIABLES = 'SET_VARIABLES',
  SET_ICP = 'SET_ICP',
  SET_COLLABORATORS = 'SET_COLLABORATORS',
  ADD_COLLABORATOR = 'ADD_COLLABORATOR',
  SET_TEMPLATE_ACTIVE_INDEX = 'SET_TEMPLATE_ACTIVE_INDEX',
  SET_SELECTED_CANDIDATE = 'SET_SELECTED_CANDIDATE',
  SET_LOADING = 'SET_LOADING',
  FETCH_SMS_NUMBERS = 'FETCH_SMS_NUMBERS',
  SET_ALLOW_VARIABLE_SAVE = "SET_ALLOW_VARIABLE_SAVE",
  SET_DRY_RUN_SMS_PHONE = "SET_DRY_RUN_SMS_PHONE"
}

export const setEditMode = () => ({
  type: CampaignRevampActions.SET_EDIT_MODE,
});

export const setPreviewMode = () => ({
  type: CampaignRevampActions.SET_PREVIEW_MODE,
});

export const setSelectedCampaign = (campaign: Campaign) => ({
  type: CampaignRevampActions.SET_SELECTED_CAMPAIGN,
  payload: {
    campaign,
  },
});

export const fetchSmsNumbers = () => ({
  type: CampaignRevampActions.FETCH_SMS_NUMBERS,
})

export const updateSelectedCampaign = (campaign: Campaign) => ({
  type: CampaignRevampActions.UPDATE_SELECTED_CAMPAIGN,
  payload: {
    campaign,
  },
});

export const markCampaignFormValid = () => ({
  type: CampaignRevampActions.MARK_CAMPAIGN_FORM_VALID,
});

export const markCampaignFormInvalid = () => ({
  type: CampaignRevampActions.MARK_CAMPAIGN_FORM_INVALID,
});

export const markVariablesValid = () => ({
  type: CampaignRevampActions.MARK_VARIABLES_VALID,
});

export const markVariablesInvalid = () => ({
  type: CampaignRevampActions.MARK_VARIABLES_INVALID,
});

export const updatekNonExistingVariables = (param: boolean) => ({
  type: CampaignRevampActions.UPDATE_NON_EXISTING_VARIABLES,
  payload: param
});

export const updatekAllowNonExistingVariables = (param: boolean) => ({
  type: CampaignRevampActions.UPDATE_ALLOW_NON_EXISTING_VARIABLES,
  payload: param
});

export const setVariables = (variables: Variable[]) => ({
  type: CampaignRevampActions.SET_VARIABLES,
  payload: {
    variables,
  },
});

export const setICP = (icp: ICP) => ({
  type: CampaignRevampActions.SET_ICP,
  payload: {
    icp,
  },
});

export const setCollaborators = (collaborators: Collaborator[]) => ({
  type: CampaignRevampActions.SET_COLLABORATORS,
  payload: {
    collaborators,
  },
});
// action creater..
export const addCollaborator = (collaborator: Collaborator, position: 'start' | 'end') => ({
  type: CampaignRevampActions.ADD_COLLABORATOR,
  payload: {
    collaborator,
    position,
  },
});

export const setTemplateActiveIndex = (index) => ({
  type: CampaignRevampActions.SET_TEMPLATE_ACTIVE_INDEX,
  payload: {
    index,
  },
});

export const setSelectedCandidate = (candidate: Candidate) => ({
  type: CampaignRevampActions.SET_SELECTED_CANDIDATE,
  payload: {
    candidate,
  },
});

export const setLoading = (loading: boolean) => ({
  type: CampaignRevampActions.SET_SELECTED_CANDIDATE,
  payload: {
    loading,
  },
});

export const setAllowVariableSave = (param: boolean) => ({
  type: CampaignRevampActions.SET_ALLOW_VARIABLE_SAVE,
  payload: param
});

export const setDryRunSMSPhone = (phone: string) => ({
  type: CampaignRevampActions.SET_DRY_RUN_SMS_PHONE,
  payload: {
    phone,
  },
});