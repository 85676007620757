import { store } from '../store/store';
import { CampaignOutreachMode, CampaignOutreachModeNames } from '../models/Campaign-option';
import { getCampaignMode } from './helpers';

export default class UrlHelper {
  private static searchParams;
  public static get(param) {
    if (!UrlHelper.searchParams) {
      UrlHelper.searchParams = new URL(window.location.href.replace('#/iframes/campaign?', '&')).searchParams;
    }
    const value = UrlHelper.searchParams.get(param);
    if (value === 'null') {
      return null;
    }
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  }

  public static isEditPage() {
    return UrlHelper.get('view') === 'campaign-edit' && !!UrlHelper.get('campaign_id');
  }

  public static isDebugMode() {
    return !!UrlHelper.get('debug2');
  }
  public static isSMSCampaignEnabled() {
    const appState = store.getState();
    const campaignOutReachMode = appState.campaignRevamp.selectedCampaign?.campaignOptions?.campaignOutReachMode ?? null;
    const mode = getCampaignMode(campaignOutReachMode);

    // Any campaign presaved with SMS enabled should retain the feature in UI
    // Anything else will require the acl+param.
    return !!UrlHelper.get('showSMSCampaign')
      ? true
      : mode !== CampaignOutreachMode.EMAIL_ONLY;
  }
}

export const getUrlParam = (param) => UrlHelper.get(param);
export const getUrlJoid = () => UrlHelper.get('joid');

export const isDebugMode = () => UrlHelper.isDebugMode();

export const isEditPage = () => UrlHelper.isEditPage();

export const isSMSCampaignEnabled = () => UrlHelper.isSMSCampaignEnabled();

function decodeURIComponentSafe(str: string | null | undefined) {
  if (!str) {
    return "";
  }
  let decoded_string: string;
  try {
    decoded_string = decodeURIComponent(str.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
  } catch (error) {
    // dont decode and just use raw string
    decoded_string = str || "";
  }
  return decoded_string;
}

const removeHTMLTags = (input: string): string => {
  try {
    input = input.replace(/<li>/g, "#") //replace li items
      .replace(/<\/li>/g, "<br/>")      //replace li items
      .replace(/<br ?\/?>/g, "\n")      //replace br
      .replace(/(<([^>]+)>)/ig, "")   //remove all tags
      .replace(/\n\n+/g, "\n")          //remove multiple \n
      .replace(/â–ªï¸/g, "▪ ")
      .replace(/â—¾/g, "▪ ")
      .replace(/â€¢/g, "▪")
      .replace(/[\?]{2,}/g, "▪")
      .replace(/â€™/g, "'")
      .trim();
    return input;
  }
  catch (e) {
    console.warn('removeHTMLTags: ' + e.message);
    return input;
  }
}

export const decodeString = (str: string | null | undefined) => {
  if ([null, undefined, "", "undefined", "null"].includes(str)) {
    return "";
  }

  let decoded_string: string = decodeURIComponentSafe(str);
  decoded_string = removeHTMLTags(decoded_string);
  const replace_chars: { [key: string]: string } = {
    "&amp;": "&",
    "&nbsp;": " "
  };
  const reducedString = Object.entries(replace_chars).reduce(
    (result_str, entry_arr) => result_str.replace(...entry_arr),
    decoded_string
  );
  return unescape(reducedString);
};