import { AuthActions } from './auth.actions';
import { User } from '../../models/User';
import { AuthStoreState } from './interfaces/auth-store-state.interface';

const initialState: AuthStoreState = {
  user: new User({
    uid: null,
    email: null,
    name: null,
    company: null,
    upgraded: null,
  }),
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AuthActions.SET_USER:
      return { ...state, user: action.payload.user.clone() };
    case AuthActions.UNSET_USER:
      return { ...state, user: initialState.user.clone() };
    case AuthActions.UPGRADE_PLAN:
      return { ...state, user: state.user.update({ upgraded: true }).clone() };
    case AuthActions.DOWNGRADE_PLAN:
      return { ...state, user: state.user.update({ upgraded: false }).clone() };
    default:
      return state;
  }
};
