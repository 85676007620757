import { assignDefined } from '../utils/object';

export enum EmailSelectionPolicy {
  PERSONAL_AND_WORK = 1,
  PERSONAL = 2,
  WORK = 3,
  PERSONAL_OVER_WORK = 4,
}

export enum ExecutionMode {
  MANUAL = 0,
  AUTOMATIC = 1,
}

export enum CampaignOutreachMode {
  EMAIL_ONLY = "EMAIL_ONLY",
  EMAIL_AND_SMS = "EMAIL_AND_SMS",
  SMS_ONLY = "SMS_ONLY",
  EMAIL_IF_NO_SMS = "EMAIL_IF_NO_SMS"
}

export enum CampaignOutreachModeNames {
  EMAIL_ONLY = "Email",
  EMAIL_AND_SMS = "EMAIL+SMS",
  SMS_ONLY = "SMS",
  EMAIL_IF_NO_SMS = "SMS>Email"
}

export enum ActivePaneTab {
  Email = "EMAIL",
  SMS = "SMS"
}
export class CampaignOption {
  uid: string;
  userEmail: string;
  selMode = 0;
  autoSelectNewshortlist = false;
  minRating: number = 3;
  ccSelf = false;
  sendPlatform: 0 | 1 | 2 = 0; // default: 'Findem', 0='Findem', 1='Gmail' 2='Outlook'
  campaignQuestionsEnabled = false;
  campaignQuestions = [];
  senderName: string;
  senderPrIdUid: string;
  senderEmail: string;
  message_limit: number;
  recipientName: string;
  cc: string[] = [];
  replyRecipients: string[] = [];
  execMode: ExecutionMode = ExecutionMode.AUTOMATIC;
  addUnsubscribe = false;
  emailSelectionPolicy = EmailSelectionPolicy.PERSONAL;
  fmUserId: string;
  threaded: boolean = false;
  campaignVariableOverrides: any[];
  campaignOutReachMode?: CampaignOutreachMode;
  activeTabPane?: ActivePaneTab;
  smsReplyRecipients: string[] = [];

  sms_sender_number?: string;
  sms_sender_country_code?: string;
  sms_sender_area_code?: string;
  sms_sender_phone_number?: string;

  static getPlatformLabel(platform: 0 | 1 | 2) {
    switch (platform) {
      case 0:
        return 'Findem';
      case 1:
        return 'Gmail';
      case 2:
        return 'Outlook';
      default:
        return '';
    }
  }

  get platformLabel() {
    return CampaignOption.getPlatformLabel(this.sendPlatform);
  }

  // Clone is called on set and update.
  // We want to separate the behaviour of the first clone vs subsequent updates
  // with the param `isSetCampaign`
  constructor(data: any, isSetCampaign: boolean = false, isTemplate: boolean = false) {
    // Global templates are created by Findem employees
    // Scrub their email from reply to's.
    if (isSetCampaign && isTemplate) {
      data.userEmail = '';
    }

    this.setData(data);
  }

  set sendPlatformLabel(value: 'Findem' | 'Gmail' | 'Outlook') {
    switch (value) {
      case 'Gmail':
        this.sendPlatform = 1;
        break;
      case 'Outlook':
        this.sendPlatform = 2;
        break;
      default:
        this.sendPlatform = 0;
        break;
    }
  }

  setData(data: Partial<CampaignOption>) {
    assignDefined(this, data);
    if (data.sendPlatformLabel) {
      this.sendPlatformLabel = data.sendPlatformLabel;
    }
    if (data.minRatingValue) {
      this.minRatingValue = data.minRatingValue;
    }
  }

  set minRatingValue(value: number) {
    const values: Partial<CampaignOption> = {
      minRating: value,
      autoSelectNewshortlist: false,
      selMode: 0,
    };
    if (value > 1) {
      values.minRating = value;
      values.autoSelectNewshortlist = true;
      values.selMode = 1;
    }
    this.setData(values);
  }

  get minRatingValue(): number {
    return this.minRating < 3 ? 1 : this.minRating;
  }

  clone(isSetCampaign: boolean = false, isTemplate: boolean = false): CampaignOption {
    return new CampaignOption(this, isSetCampaign, isTemplate);
  }

  public static getEmailSelectionPolicyList(outreachMode: CampaignOutreachMode) {
    if (outreachMode === CampaignOutreachMode.SMS_ONLY) return [];
    return [
      {
        label: "Candidates' personal and work emails",
        value: EmailSelectionPolicy.PERSONAL_AND_WORK,
      },
      {
        label: "Candidates' personal emails only",
        value: EmailSelectionPolicy.PERSONAL,
      },
      {
        label: "Candidates' work emails only",
        value: EmailSelectionPolicy.WORK,
      },
      {
        label: "Candidates' personal emails, or work if not available",
        value: EmailSelectionPolicy.PERSONAL_OVER_WORK,
      },
    ];
  }
  public static getCampaignDispatchMode() {
    return [
      {
        label: "Email Only",
        value: CampaignOutreachMode.EMAIL_ONLY
      },{
        label: "Email & SMS",
        value: CampaignOutreachMode.EMAIL_AND_SMS
      },{
        label: "SMS Only",
        value: CampaignOutreachMode.SMS_ONLY
      },{
        label: "Email if SMS is not available",
        value: CampaignOutreachMode.EMAIL_IF_NO_SMS
      }
    ]
  }
}