import { store } from '../store/store';
import { User } from '../models/User';
import { getUrlJoid } from './url';
import { CampaignOutreachModeNames, CampaignOutreachMode } from '../models/Campaign-option';

export const getICPOwnerUser = (): any => {
  const user: User = store.getState().auth.user;
  const urlJoId = getUrlJoid();
  if (urlJoId) {
    return {
      uid: urlJoId
    };
  }
  if (!user) {
    throw new Error('Unauthenticated');
  }
  if (user && user.jobOwner && user.jobOwner.uid !== user.uid) {
    return user.jobOwner;
  }
  return user;
};

export const getJobOwnerUser = (): any => {
  const user: User = store.getState().auth.user;
  if (!user) {
    throw new Error('Unauthenticated');
  }
  if (user && user.jobOwner && user.jobOwner.uid !== user.uid) {
    return user.jobOwner;
  }
  return user;
};

export const getJobOwnerUserId = (): string | null => {
  const joid = getUrlJoid();
  if (joid) {
    return joid;
  }
  return getJobOwnerUser() ? getJobOwnerUser().uid : null;
};

export const DUMMY_PRID = '5bc81c0d7f79640007b2857e';

export const getCampaignMode = (campaignOutReachMode) => {
  const mode = campaignOutReachMode && Object.keys(CampaignOutreachModeNames).includes(campaignOutReachMode)
    ? campaignOutReachMode
    : CampaignOutreachMode.EMAIL_ONLY;

  return mode;
}