import React from 'react';
import { Redirect, Route as ReactRoute, withRouter } from 'react-router';
import { RouterGuard } from '../guard/interfaces/router-guard.interface';
import { from, Observable, of, zip } from 'rxjs';
import { RouterResolver } from '../resolver/interfaces/router-resolver.interface';
import { Params } from '../../types/params';
import { ExtendedRouteComponentProps } from '../../types/extended-route-component-props';

class Route extends React.Component<ExtendedRouteComponentProps> {
  state = {
    canActivate: null,
    resolved: false,
    resolve: null,
  };

  componentDidMount(): void {
    this.checkAllGuards();
    this.resolve();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.path !== prevProps.path) {
      this.setState({
        canActivate: null,
        resolved: false,
        resolve: null,
      });
      this.checkAllGuards();
      this.resolve();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const loader = <div>Loading...</div>;
    if (this.state.canActivate === null) {
      return loader;
    } else if (!this.state.canActivate) {
      return <Redirect to="/" />;
    } else if (!this.state.resolved) {
      return loader;
    }

    return (
      <ReactRoute
        {...rest}
        render={(props) => <Component {...props} resolve={this.state.resolve} />}
      />
    );
  }

  private checkAllGuards(): void | never {
    const errorMessage = `The {guards} property of <Route /> should be an array of RouterGuard's`;
    const Guards: (new () => RouterGuard)[] = this.props.guards;
    if (!Guards) {
      this.setState({ canActivate: true });
    } else if (!Array.isArray(Guards)) {
      throw new Error(errorMessage);
    } else if (!Guards.length) {
      this.setState({ canActivate: true });
    } else {
      const canActivateObs = [];
      Guards.forEach((Guard) => {
        const guard = new Guard();
        const canActivate = guard.canActivate();
        if (canActivate instanceof Observable) {
          canActivateObs.push(canActivate);
        } else if (canActivate instanceof Promise) {
          canActivateObs.push(from(canActivate));
        } else if (typeof canActivate === 'boolean') {
          canActivateObs.push(of(canActivate));
        } else {
          throw new Error(errorMessage);
        }
      });
      zip<boolean[]>(...canActivateObs).subscribe((conditions) => {
        const canActivate = conditions.every((c) => c);
        if (canActivate) {
          this.setState({ canActivate });
        } else {
          this.props.history.goBack();
        }
      });
    }
  }

  private resolve(): void {
    const resolvers: { [key: string]: new () => RouterResolver }[] = this.props.resolve;
    if (!resolvers || !Object.keys(resolvers).length) {
      this.setState({ resolved: true });
    } else {
      const resolverObs = {};
      Object.keys(resolvers).forEach((key: string) => {
        const resolver = new resolvers[key]();
        const data = resolver.resolve();
        if (data instanceof Observable) {
          resolverObs[key] = data;
        } else if (data instanceof Promise) {
          resolverObs[key] = from(data);
        } else {
          resolverObs[key] = of(data);
        }
      });
      zip<Params[]>(...Object.values<Observable<any>>(resolverObs)).subscribe((resolvedData) => {
        const resolve = {};
        Object.keys(resolverObs).forEach((key: string, index: number) => {
          resolve[key] = resolvedData[index];
        });
        this.setState({ resolve, resolved: true });
      });
    }
  }
}

export default withRouter(Route);
