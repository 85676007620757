import { Variable } from '../models/Variable';
import { Campaign } from '../models/Campaign';
import { CampaignOutreachMode, CampaignOutreachModeNames } from '../models/Campaign-option';
import { getCampaignMode } from './helpers';

const variableRegExp = new RegExp(`(@[a-zA-Z0-9_]+_[a-zA-Z0-9]+)`, 'gmi');

const highlightRegExp = (variable: string) => new RegExp(`(@${variable})`, 'gmi');

export const highlightVariables = (text: string, variablesKeys: Array<string>, type: "unresolved" | "link" | "non-existing") => {
  let newText: string = text;

  variablesKeys.forEach((variable: string) => {
    const regExp = highlightRegExp(variable);
    newText = newText.replace(regExp, `<span class="highlighted-variable ${type}" title="Variable does not exist">$1</span>`);
  });

  return newText;
}

export const unhighlightVariables = (text: string, type: "all" | "unresolved" | "link" = "all") => {
  const pattern = new RegExp(`<span[^>]*highlighted-variable${type === "all" ? "" : " " + type}[^>]*>(.*?)<\/span>`, "gmi");

  return text.replace(pattern, '$1');
}

export const getNonExistingVariables = (text: string, variables: Array<Variable>) => {
  const allNonExistingVariables: Array<string> = [];
  const variableKeys: Array<string> = variables.map((variable: Variable) => variable.key);

  text?.match(variableRegExp)?.forEach((match: string) => {
    const cleanMatch = match.trim().replace('@', '');
    if (!variableKeys.includes(cleanMatch) && !allNonExistingVariables.includes(cleanMatch)) {
      allNonExistingVariables.push(cleanMatch);
    }
  });

  return allNonExistingVariables;
}

export const highlightTemplatesVariables = (selectedCampaign: Campaign, variables: Array<Variable>) => {
  const {
    hasNonExistingVariables,
    emailNonExistingVariables,
  } = templatesHasNonExistingVariables(selectedCampaign, variables);
  let hasChanges: boolean = false;

  selectedCampaign.templates.forEach((template) => {
    let newTemplateContent = unhighlightVariables(template.content);

    if (emailNonExistingVariables.length) {
      newTemplateContent = highlightVariables(newTemplateContent, emailNonExistingVariables, 'non-existing');
    }

    if (newTemplateContent !== template.content) {
      template.setData({
        content: newTemplateContent
      });
      hasChanges = true;
    }
  });


  return {
    selectedCampaign,
    hasNonExistingVariables,
    hasChanges,
  }
}

export const templatesHasNonExistingVariables = (selectedCampaign: Campaign, variables: Array<Variable>) => {
  return {
    hasNonExistingVariables: false,
    emailNonExistingVariables: [],
    smsNonExistingVariables: false,
  }

  const mode = getCampaignMode(selectedCampaign.campaignOptions.campaignOutReachMode);

  let emailNonExistingVariables: Array<string> = [];
  let smsNonExistingVariables: Array<string> = [];

  selectedCampaign.templates.forEach((template) => {
    if (mode !== CampaignOutreachMode.SMS_ONLY) {
      emailNonExistingVariables = [
        ...emailNonExistingVariables,
        ...getNonExistingVariables(template.subject, variables),
        ...getNonExistingVariables(template.content, variables),
      ];
    }

    if (mode !== CampaignOutreachMode.EMAIL_ONLY) {
      smsNonExistingVariables = [
        ...smsNonExistingVariables,
        ...getNonExistingVariables(template.smsContent, variables),
      ];
    }
  });

  const hasNonExistingVariables: boolean = !!emailNonExistingVariables.length || !!smsNonExistingVariables.length;

  return {
    hasNonExistingVariables,
    emailNonExistingVariables,
    smsNonExistingVariables,
  }
};
