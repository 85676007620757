import { HashRouter, Switch } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { AuthGuard } from '../../guards/Auth.guard';
import Route from '../../core/router/Route';

const Home = lazy(() => import('./../pages/home/Home'));
const Dashboard = lazy(() => import('./../pages/dashboard/Dashboard'));
const Iframe = lazy(() => import('../pages/iframes/Iframes'));

export default function Outlet() {
  return (
    <HashRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/dashboard" component={Dashboard} guards={[AuthGuard]} />
          <Route path="/iframes" component={Iframe} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}
