import { RouterGuard } from '../core/guard/interfaces/router-guard.interface';
import { store } from '../store/store';
import { State } from '../store/interfaces/state.interface';
import { UserService } from '../services/http/user.service';
import { map } from 'rxjs/operators';

export class AuthGuard implements RouterGuard {
  private userService = new UserService();

  canActivate() {
    const state: State = store.getState();
    const uid = localStorage.getItem('auth_user_uid');
    if (state.auth.user.uid) {
      return true;
    } else if (uid) {
      return this.userService.me().pipe(map((user) => !!user));
    }
    return false;
  }
}
