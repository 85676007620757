import { matchesUrl, pubUrl, collabUrl } from "./config-urls";

export default class Config {
  static get(key: string, defaultValue: string = '') {
    const envKey = `REACT_APP_${key.toUpperCase()}`;

    // Not going both rewritting all the urls in the components as
    // We're going to be sunsetting this code just going to return the new urls.

    switch (envKey) {
      case 'REACT_APP_BASE_GUEST_API_URL':
        return pubUrl;
      case 'REACT_APP_BASE_AUTH_API_URL':
        return matchesUrl;
      case 'REACT_APP_BASE_COLLABORATORS_API_URL':
        return collabUrl;
      default:
        // null
    }

    if (process.env[envKey]) {
      return process.env[envKey];
    }
    return defaultValue;
  }
}
