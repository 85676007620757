import { CampaignRevampActions } from './campaign-revamp.actions';
import { CampaignRevampStoreState } from './interfaces/campaign-revamp-store-state.interface';
import {
  highlightTemplatesVariables,
  templatesHasNonExistingVariables,
} from '../../utils/variables';

const initialState: CampaignRevampStoreState = {
  editMode: true,
  selectedCampaign: null,
  isCampaignFormValid: false,
  areVariablesValid: false,
  hasNonExistingVariables: false,
  allowNonExistingVariables: false,
  variables: [],
  icp: null,
  collaborators: [],
  activeTemplateIndex: null,
  selectedCandidate: null,
  loading: false,
  allowVariableSave: false,
  dryRunSMSPhone: '',
};

// reducer for camprevamp
export const campaignRevampReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CampaignRevampActions.SET_EDIT_MODE:
      return { ...state, editMode: true };
    case CampaignRevampActions.SET_PREVIEW_MODE:
      return { ...state, editMode: false };
    case CampaignRevampActions.SET_SELECTED_CAMPAIGN:
      const setSelectedCampaign = action.payload.campaign.clone(true);
      const { selectedCampaign, hasNonExistingVariables } = highlightTemplatesVariables(setSelectedCampaign, state.variables);

      return {
        ...state,
        selectedCampaign,
        activeTemplateIndex: null,
        allowNonExistingVariables: false,
        hasNonExistingVariables,
      };
    case CampaignRevampActions.UPDATE_SELECTED_CAMPAIGN:
      const {
        hasNonExistingVariables: updateHasNonExistingVariables
       } = templatesHasNonExistingVariables(action.payload.campaign, state.variables);

      return {
        ...state,
        selectedCampaign: action.payload.campaign.clone(),
        hasNonExistingVariables: updateHasNonExistingVariables,
      };
    case CampaignRevampActions.MARK_CAMPAIGN_FORM_VALID:
      return { ...state, isCampaignFormValid: true };
    case CampaignRevampActions.MARK_CAMPAIGN_FORM_INVALID:
      return { ...state, isCampaignFormValid: false };
    case CampaignRevampActions.MARK_VARIABLES_VALID:
      return { ...state, areVariablesValid: true };
    case CampaignRevampActions.MARK_VARIABLES_INVALID:
      return { ...state, areVariablesValid: false };
    case CampaignRevampActions.SET_VARIABLES:
      return { ...state, variables: action.payload.variables };
    case CampaignRevampActions.SET_ICP:
      return { ...state, icp: action.payload.icp.clone() };
    case CampaignRevampActions.SET_COLLABORATORS:
      return { ...state, ...{ collaborators: action.payload.collaborators } };
    case CampaignRevampActions.ADD_COLLABORATOR:
      return {
        ...state,
        ...{
          collaborators:
            action.payload.position === 'start'
              ? [action.payload.collaborator, ...state.collaborators]
              : [...state.collaborators, action.payload.collaborator],
        },
      };
    case CampaignRevampActions.SET_TEMPLATE_ACTIVE_INDEX:
      return { ...state, activeTemplateIndex: action.payload.index };
    case CampaignRevampActions.SET_SELECTED_CANDIDATE:
      return { ...state, selectedCandidate: action.payload.candidate };
    case CampaignRevampActions.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case CampaignRevampActions.SET_ALLOW_VARIABLE_SAVE:
      return { ...state, allowVariableSave: action.payload }
    case CampaignRevampActions.UPDATE_NON_EXISTING_VARIABLES:
      return {
        ...state,
        hasNonExistingVariables: action.payload,
        allowNonExistingVariables: false
      }
    case CampaignRevampActions.UPDATE_ALLOW_NON_EXISTING_VARIABLES:
      return {
        ...state,
        allowNonExistingVariables: action.payload
      }
    case CampaignRevampActions.SET_DRY_RUN_SMS_PHONE:
      return {
        ...state,
        dryRunSMSPhone: action.payload.phone,
      }
    default:
      return state;
  }
};
