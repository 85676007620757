import React from 'react';
import './App.scss';
import Outlet from './Routes';
import { Provider } from 'react-redux';
import { store } from '../../store/store';

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

function App() {
  return (
    <Provider store={store}>
      <Outlet />
    </Provider>
  );
}

export default App;
