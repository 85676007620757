import { authReducer } from './auth/auth.reducer';
import { createStore, combineReducers, applyMiddleware, compose as composeRoot } from 'redux';
import { campaignRevampReducer } from './campaign-revamp/campaign-revamp.reducer';
import Config from '../utils/config';

const rootRenderer = combineReducers({
  auth: authReducer,
  campaignRevamp: campaignRevampReducer,
});

const middlewares = [];
let compose = composeRoot;

if (Config.get('development_mode')) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);

  compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const store = compose(applyMiddleware(...middlewares))(createStore)(rootRenderer);
