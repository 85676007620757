export enum AuthActions {
  SET_USER = 'SET_USER',
  UNSET_USER = 'UNSET_USER',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  DOWNGRADE_PLAN = 'DOWNGRADE_PLAN',
}

export const setUser = (user) => ({
  type: AuthActions.SET_USER,
  payload: {
    user,
  },
});

export const unsetUser = () => ({
  type: AuthActions.UNSET_USER,
});

export const upgradePlan = () => ({
  type: AuthActions.UPGRADE_PLAN,
});

export const downgradePlan = () => ({
  type: AuthActions.DOWNGRADE_PLAN,
});
