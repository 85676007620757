import { BaseHttpService } from './base-http-service';
import { Observable, of } from 'rxjs';
import { Params } from '../../types/params';
import { User } from '../../models/User';
import { map, switchMap, tap } from 'rxjs/operators';
import { store } from '../../store/store';
import { setUser, unsetUser } from '../../store/auth/auth.actions';
import randomString from 'random-string';
import Config from '../../utils/config';
import { getUrlJoid } from '../../utils/url';

export class UserService extends BaseHttpService {
  login(params: Params): Observable<User> {
    return this.http.post('auth/login', { ...params, ...{ api: true } }).pipe(
      map((res) => new User(res.data)),
      tap(this.setUser)
    );
  }

  me(): Observable<User> {
    const authUserId = localStorage.getItem('auth_user_uid');
    const joid = getUrlJoid();
    if (joid && joid !== authUserId) {
      return this.userInfo(joid).pipe(
        switchMap((jobOwner) => {
          return of(jobOwner);
        }),
        tap(this.setUser)
      );
    }
    return this.userInfo(authUserId).pipe(
      switchMap((user) => {
        // if (joid && joid !== authUserId) {
        //   return this.userInfo(joid).pipe(
        //     switchMap((jobOwner) => {
        //       user.setData({ jobOwner });
        //       return of(user);
        //     })
        //   );
        // }
        return of(user);
      }),
      tap(this.setUser)
    );
  }

  userInfo(id: string): Observable<User> {
    return this.http
      .post('pub/api/user_info', { id }, { baseURL: Config.get('base_guest_api_url') })
      .pipe(map(({ data }) => new User(data)));
  }

  getUsers() {
    return this.http.get('hm/api/users').pipe(
      map(({ data }) =>
        data.map((user) => {
          return new User({
            uid: randomString(), // TODO
            name: user.local.name,
            email: user.local.email,
            company: user.local.company_name,
          });
        })
      )
    );
  }

  forgotPassword(params: Params) {
    return this.http.post('pub/api/user_admin', params).pipe(map((res) => new User(res.data)));
  }

  confirmEmail(params: Params) {
    return this.login(params);
  }

  resetPassword(params: Params) {
    return this.forgotPassword({ ...params, ...{ type: 'ResetPassword' } });
  }

  createQuery(params: FormData) {
    return this.http.post(`pub/api/macro`, params);
  }

  logout(): Observable<any> {
    return this.http.get('auth/logout?api=true').pipe(
      tap(() => {
        localStorage.removeItem('auth_user_uid');
        store.dispatch(unsetUser());
      })
    );
  }

  private setUser(user: User): void {
    localStorage.setItem('auth_user_uid', user.uid);
    store.dispatch(setUser(user));
  }
}
