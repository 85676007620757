import { AxiosRequestConfig } from 'axios';
import AxiosObservable from 'axios-observable';
import Axios, { AxiosInstance } from 'axios';
// import { notification } from 'antd';
import Config from '../../utils/config';
import { getJobOwnerUserId } from '../../utils/helpers';

export class BaseHttpService {
  protected defaultConfigs: AxiosRequestConfig = {
    withCredentials: true,
    baseURL: Config.get('base_auth_api_url'),
  };
  protected configs: AxiosRequestConfig;
  protected http: AxiosObservable;
  protected httpPromise: AxiosInstance;
  public appendJoid = true;

  constructor(configs: AxiosRequestConfig = {}) {
    this.configs = configs;
    this.http = AxiosObservable.create({ ...this.defaultConfigs, ...this.configs });
    this.http.interceptors.request.use(
      (config) => {
        return this.appendJoidToConfig(config);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.http.interceptors.response.use(
      (data) => data,
      (data) => {
        console.error('Server Error', data && data.message);
        return data;
      }
    );
    this.httpPromise = Axios.create({ ...this.defaultConfigs, ...this.configs });
  }

  // public openNotification(
  //   message: string,
  //   description?: string,
  //   type: 'success' | 'info' | 'warning' | 'error' = 'error'
  // ) {
  //   notification[type]({ message, description });
  // }

  private appendJoidToConfig(config: AxiosRequestConfig) {
    if (this.appendJoid) {
      const isPostMethod = config.method === 'post';
      const isFindemRequest = config.baseURL.includes('.findem.ai');
      if (isPostMethod && isFindemRequest) {
        try {
          config.data = { ...config.data, joid: getJobOwnerUserId() };
        } catch (e) {
          console.error('Request Interceptor Failure: Unable to append joid', e);
        }
      }
    }
    return config;
  }
}
